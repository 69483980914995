import React, { useState, useEffect } from "react";
import './App.css';
import emailjs from '@emailjs/browser';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import WhatWeDo from "./sections/wwd";
import OurServices from "./sections/os";
import OurVision from "./sections/ov";
import TrustedBy from "./sections/tb";
import MySkills from "./sections/ms";

function App() {
  const [scrollY, setScrollY] = useState(0);
  const [formData, setFormData] = useState({ name: "", email: "", message: "" });
  const [isSending, setIsSending] = useState(false); 
  const [isMenuOpen, setIsMenuOpen] = useState(false); 
  const [isGerman, setIsGerman] = useState(true);

  const technologies = [
    { name: "Java", logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-original.svg" },
    { name: "JavaScript", logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg" },
    { name: "React", logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg" },
    { name: "Python", logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg" },
    { name: "Shell", logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bash/bash-original.svg" },
    { name: "NodeJS", logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg" },
    { name: "Flutter", logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/flutter/flutter-original.svg" },
  ];
  
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);  
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
      console.log(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const sendMail = (e) => {
    e.preventDefault();
    setIsSending(true);

    emailjs
      .send(
        "service_wgu6oza", 
        "template_zb9d6it", 
        {
          name: formData.name,
          email: formData.email,
          message: formData.message,
        },
        "sprVHUk6uS_2Y4zxS" 
      )
      .then(
        (response) => {
          setFormData({ name: "", email: "", message: "" }); 
        },
        (error) => {
          console.error("Error sending email:", error);
          alert("Failed to send message. Please try again later.");
        }
      )
      .finally(() => setIsSending(false));
  };

  const isMobile = window.innerWidth <= 768;

  const maxHeaderHeight = isMobile ? 50 : 100; 
  const minHeaderHeight = isMobile ? 15 : 20; 
  const headerHeight = Math.max(
    minHeaderHeight,
    maxHeaderHeight - scrollY * 0.1
  );

  const navDisHeight = isMobile ? 45 : 60;
  const headerHeightNav = Math.max(
    navDisHeight,
    maxHeaderHeight - scrollY * 0.1
  )

  const maxPadHeight = 20;
  const minPadHeight = 10;
  const padTopC = Math.max(
    minPadHeight,
    maxPadHeight - scrollY * 0.1
  );

  const isNavbar = headerHeight === minHeaderHeight;
  const displayNavTitle = headerHeightNav === navDisHeight;

  return (
    <div className="App">
    <head>
    <title>Leu Consulting</title>
    <link rel="icon" href="assets/favicon-white.png"></link>
    </head>
      <header
        className="d-flex align-items-center top-0 w-100"
        style={{
          backgroundColor: "var(--primary-color)",
          height: `${headerHeight}vh`,
          color: "var(--background-color)",
          backgroundImage: "url('assets/bg-img.png')",
          backgroundSize: "cover",
          zIndex: 1020,
          justifyContent: "center",
          padding: "0 1em",
          transition: "height 0.2s ease-out, padding 0.2s ease-out",
          marginTop: isMobile ? "4em" : "0"
        }}
      >    
        <div id="title" style={{ 
          textAlign: "center", 
          zIndex: 1028,
          position: "absolute",
          }}>
          <img style={{
              transition: "text-align 0.2s ease-out, font-size 0.2s ease-out",
              textShadow: "0px 0px 18px 0px rgba(0,0,0,1)",
              width: isMobile ? "90%" : "",
              borderRadius: "0px"
            }}
            src="assets/logo-white.png"
            alt="Leu Consulting Logo"
          >
          </img>
          {!isNavbar && (
            <div
              className="align-items-center"
              style={{
                display: "flex",        
                flexDirection: "column",
                justifyContent: "center", 
                alignItems: "center",   
                marginTop: "1em",        
              }}
            >
              {!isMobile && (<p
                className="lead"
                style={{
                  color: "var(--background-color)",
                  margin: "1em",
                  textShadow: "0px 0px 18px 0px rgba(0,0,0,1)"
                }}
              >
                empowering businesses through digital innovation and streamlined automation
              </p>
              )}
              {!isGerman ? (<button
                className="btn btn-info"
                style={{
                  marginTop: "1em",
                  padding: isMobile ? ".75em" : "1em",
                  borderRadius: "20px",
                  boxShadow: "0px 0px 18px 0px rgba(0,0,0,0.1)",
                  color: "white",
                  backgroundColor: "var(--primary-color)",
                  visibility: displayNavTitle ? "hidden" : "",
                  border: "none",
                }}
                onClick={() => {
                  const contactElement = document.getElementById("contact");
                  if (contactElement) {
                    contactElement.scrollIntoView({ behavior: "smooth" });
                  }
                }}
              >
                Get In Touch &rarr;
              </button>)
              : (
                <button
                className="btn btn-info"
                style={{
                  marginTop: "1em",
                  padding: isMobile ? ".75em" : "1em",
                  borderRadius: "20px",
                  boxShadow: "0px 0px 18px 0px rgba(0,0,0,0.1)",
                  color: "white",
                  backgroundColor: "var(--primary-color)",
                  visibility: displayNavTitle ? "hidden" : "",
                  border: "none",
                }}
                onClick={() => {
                  const contactElement = document.getElementById("contact");
                  if (contactElement) {
                    contactElement.scrollIntoView({ behavior: "smooth" });
                  }
                }}
              >
                Kontakt &rarr;
              </button>
              )}
            </div>
          )}
        </div>

        <div
          style={{
            width: "100%",
            height: isMobile ? "10vh" : "7.5vh",  
            top: "0",
            left: "0",
            backgroundColor: "var(--primary-color)",
            boxShadow: "0px 28px 50px -6px rgba(0,0,0,0.15)",
            position: "fixed",
            zIndex: 1029,
            display: "flex",
            justifyContent: isMobile ? "space-between" : "center", 
            alignItems: "center",  
            paddingLeft: "2em",  
            paddingRight: "2em",  
            borderBottomRightRadius:  displayNavTitle ? "15px" : "0",
            borderBottomLeftRadius:  displayNavTitle ? "15px" : "0",
            transition: "border-bottom-right-radius 0.2s ease-out, border-bottom-right-radius 0.2s ease-out",
          }}
        >
        {isMobile ? (
          <button
            style={{
              fontSize: "30px",
              backgroundColor: "transparent",
              color: "white",
              border: "none",
              cursor: "pointer",
            }}
            onClick={toggleMenu}
            aria-label="Toggle menu"
          >
            &#9776;
          </button> 
        ) : !isGerman ? (
            <nav
            style={{
              display: "flex",
              gap: "1em",
            }}
          >
          <div className="nav-item-top">
            <a href="#what-we-do" style={{ color: "white", textDecoration: "none", }}>
              What I Do
            </a>
            </div>
            <div className="nav-item-top">
            <a href="#meet-the-team" style={{ color: "white", textDecoration: "none" }}>
              This Is Me
            </a>
            </div>
            <div className="nav-item-top">
            <a href="#our-vision" style={{ color: "white", textDecoration: "none" }}>
              My Vision
            </a>
            </div>
            <div className="nav-item-top">
            <a href="#thisiswhatioffer" style={{ color: "white", textDecoration: "none" }}>
              My Services
            </a>
            </div>

          </nav>
          ) : (
            <nav
            style={{
              display: "flex",
              gap: "1em",
            }}
          >
          <div className="nav-item-top">
            <a href="#what-we-do" style={{ color: "white", textDecoration: "none", }}>
              Was biete ich?
            </a>
            </div>
            <div className="nav-item-top">
            <a href="#meet-the-team" style={{ color: "white", textDecoration: "none" }}>
              Über mich
            </a>
            </div>
            <div className="nav-item-top">
            <a href="#our-vision" style={{ color: "white", textDecoration: "none" }}>
              Meine Vision
            </a>
            </div>
            <div className="nav-item-top">
            <a href="#thisiswhatioffer" style={{ color: "white", textDecoration: "none" }}>
              Meine Dienstleistungen
            </a>
            </div>
          </nav>
          )
        }

          {!isGerman ? (<button
            className="btn btn-info"
            style={{
              display: displayNavTitle ? "block" : "none",  
              color: "white",
              border: "none",
              backgroundColor: "var(--primary-color)",
              boxShadow: "0px 0px 18px 0px rgba(0,0,0,0.2)",
              marginLeft: isMobile ? "" : "5em",
            }}
            onClick={() => {
              const contactElement = document.getElementById("contact");
              if (contactElement) {
                contactElement.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            Get In Touch &rarr;
          </button>
          ) : (
            <button
            className="btn btn-info"
            style={{
              display: displayNavTitle ? "block" : "none",  
              color: "white",
              border: "none",
              backgroundColor: "var(--primary-color)",
              boxShadow: "0px 0px 18px 0px rgba(0,0,0,0.2)",
              marginLeft: isMobile ? "" : "5em",
            }}
            onClick={() => {
              const contactElement = document.getElementById("contact");
              if (contactElement) {
                contactElement.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            Kontakt &rarr;
          </button>
          )}
        {isMobile ? (<button
          style={{
            marginLeft: "1em",
            backgroundColor: "transparent",
            color: "white",
            border: "1px solid white",
            borderRadius: "5px",
            padding: "0.5em 1em",
            cursor: "pointer",
          }}
          onClick={() => {
            setIsGerman(!isGerman); 
          }}
        >
          {isGerman ? "EN" : "DE"}
        </button>)
        : (
          <button
          style={{
            marginLeft: "1em",
            backgroundColor: "transparent",
            color: "white",
            border: "1px solid white",
            borderRadius: "5px",
            padding: "0.5em 1em",
            cursor: "pointer",
            position: "absolute",
            top: "1em",
            right: "1em"
          }}
          onClick={() => {
            setIsGerman(!isGerman); 
          }}
        >
          {isGerman ? "EN" : "DE"}
        </button>
        )}
        </div>

        {isMobile && (<div
          id="side-nav"
          style={{
            width: isMenuOpen ? (isMobile ? "65%" : "25%") : "0",
            position: "fixed",
            zIndex: 1030,
            top: 0,
            left: 0,
            height: "100%",
            backgroundColor: "var(--accent-color)",
            overflowX: "hidden",
            paddingTop: "60px",
            transition: "width 0.3s ease",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <a
            style={{
              position: "absolute",
              top: "10px",
              right: "25px",
              fontSize: "36px",
              textDecoration: "none",
              color: "white",
              cursor: "pointer",
            }}
            onClick={() => setIsMenuOpen(false)}
          >
            &times;
          </a>
          
          <div
            style={{
              padding: "20px 32px",
              fontSize: "30px",
              color: "white",
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: "20px",  
              display: displayNavTitle ? "block" : "none",
            }}
          >
            Leu Consulting
          </div>
          
          {!isGerman ? (<div style={{ flex: 1 }}>
            <a
              href="#what-we-do"
              style={{
                padding: "8px 8px 8px 32px",
                textDecoration: "none",
                fontSize: "25px",
                color: "white",
                display: "block",
              }}
            >
              What I Do
            </a>
            <a
              href="#meet-the-team"
              style={{
                padding: "8px 8px 8px 32px",
                textDecoration: "none",
                fontSize: "25px",
                color: "white",
                display: "block",
              }}
            >
              About Me
            </a>
            <a
              href="#our-vision"
              style={{
                padding: "8px 8px 8px 32px",
                textDecoration: "none",
                fontSize: "25px",
                color: "white",
                display: "block",
              }}
            >
              My Vision
            </a>
            <a
              href="#thisiswhatioffer"
              style={{
                padding: "8px 8px 8px 32px",
                textDecoration: "none",
                fontSize: "25px",
                color: "white",
                display: "block",
              }}
            >
              My services
            </a>
            
            <hr
              style={{
                border: "none",
                borderTop: "1px solid white",
                margin: "20px 0",
              }}
            />

            <a
              href="#contact"
              style={{
                padding: "8px 8px 8px 32px",
                textDecoration: "none",
                fontSize: "25px",
                color: "white",
                display: "block",
              }}
            >
              Contact
            </a>
          </div>)
          : (
            <div style={{ flex: 1 }}>
            <a
              href="#what-we-do"
              style={{
                padding: "8px 8px 8px 32px",
                textDecoration: "none",
                fontSize: "25px",
                color: "white",
                display: "block",
              }}
            >
              Was biete ich?
            </a>
            <a
              href="#meet-the-team"
              style={{
                padding: "8px 8px 8px 32px",
                textDecoration: "none",
                fontSize: "25px",
                color: "white",
                display: "block",
              }}
            >
              Über mich
            </a>
            <a
              href="#our-vision"
              style={{
                padding: "8px 8px 8px 32px",
                textDecoration: "none",
                fontSize: "25px",
                color: "white",
                display: "block",
              }}
            >
              Meine Vision
            </a>
            <a
              href="#thisiswhatioffer"
              style={{
                padding: "8px 8px 8px 32px",
                textDecoration: "none",
                fontSize: "25px",
                color: "white",
                display: "block",
              }}
            >
              Meine Dienstleistungen
            </a>
            <hr
              style={{
                border: "none",
                borderTop: "1px solid white",
                margin: "20px 0",
              }}
            />

            <a
              href="#contact"
              style={{
                padding: "8px 8px 8px 32px",
                textDecoration: "none",
                fontSize: "25px",
                color: "white",
                display: "block",
              }}
            >
              Kontakt
            </a>
          </div>
          )}
            
          <div
            style={{
              padding: "10px 32px",
              fontSize: "14px",
              color: "white",
              textAlign: "center",
            }}
          >
            © 2025 Leu Consulting
          </div>
        </div>
        )}
      </header>

      <WhatWeDo german={isGerman}/>

      <section className="bg-light py-5" style={{ 
        paddingTop: `${padTopC}em`,
        boxShadow: "0px -3px 50px -6px rgba(0,0,0,0.4)",
        }}>
        <div className="container">
          <div className="row">
            {!isGerman ? (
              <div className="col-md-6">
                <h2 id="meet-the-team">About Me</h2>
                <p>
                As a computer science student with nearly three years of experience in software development, 
                process, and project management, I am passionate about digitalization and automation. My goal 
                is to support companies with my knowledge, helping them stay competitive in the dynamic world 
                of digital transformation and RPA.
                </p>
              </div>
            ) : (
              <div className="col-md-6">
                <h2 id="meet-the-team">Über mich</h2>
                <p>
                Als Informatikstudent mit fast drei Jahren Erfahrung in Softwareentwicklung, Prozess- und Projektmanagement 
                begeistere ich mich für Digitalisierung und Automatisierung. Mein Ziel ist es, Unternehmen mit meinem Wissen 
                zu unterstützen, damit sie in der dynamischen Welt der digitalen Transformation und RPA wettbewerbsfähig bleiben.
                </p>
              </div>
            )}
            <div className="col-md-6">
    <a 
        href="https://www.linkedin.com/in/maxwell-leu-a33334225/" 
        target="_blank" 
        rel="noopener noreferrer"
    >
        <img
            src="/assets/me.jpeg"
            alt="Maxwell Leu"
            className="img-fluid rounded shadow-lg"
        />
    </a>
</div>

          </div>
        </div>
        <div 
    style={{ 
      width: "100%", 
      textAlign: "center", 
      marginTop: "2em",
      fontSize: "2.5rem", 
      fontWeight: "bold", 
      color: "rgba(0, 0, 0, 0.6)" 
    }}
  >
    {!isGerman ? ("Trust me, I'll find a way ") : 
    ("Vertrauen Sie mir, ich finde eine Lösung ")}
    <i class="bi bi-rocket-takeoff"></i>
  </div>
      </section>

      <OurVision german={isGerman}/>
       <MySkills german={isGerman} />
 
       <section
  className="technologies-section bg-secondary"
  style={{
    padding: "4em 1em",
    textAlign: "center",
  }}
>
  <div className="container">
    
    <div
      className="row"
      style={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        gap: "1.5rem",
      }}
    >
      {technologies.map((tech, index) => (
        <div
          key={index}
          className="col-md-2 col-sm-4 tech-card"
          style={{
            background: "var(--accent-color)",
            borderRadius: "12px",
            padding: "1.5em",
            textAlign: "center",
            transition: "transform 0.3s ease, box-shadow 0.3s ease",
            maxWidth: "150px",
            boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = "translateY(-10px)";
            e.currentTarget.style.boxShadow = "0 10px 25px rgba(0, 0, 0, 0.2)";
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = "translateY(0)";
            e.currentTarget.style.boxShadow = "0px 5px 15px rgba(0, 0, 0, 0.1)";
          }}
        >
          <img
            src={tech.logo}
            alt={tech.name}
            className="experience-logo"
            style={{
              width: "80px",
              height: "80px",
              marginBottom: "1em",
              filter: "drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.2))",
            }}
          />
          <h5
            className="text-highlight"
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              color: "#fff", // Ensure white color explicitly
              marginBottom: "0.5em",
            }}
          >
            {tech.name}
          </h5>
        </div>
      ))}
    </div>
  </div>
</section>



      <section
        className="bg-dark text-white py-5"
        style={{
          backgroundColor: "#f8f9fa", 
          color: "#212529", 
          boxShadow: "0px -3px 50px -6px rgba(0,0,0,0.4)",
        }}
      >
        {!isGerman ? (<div className="container" id="contact">
          <h2 className="text-center mb-4">Want to know more?</h2>
          <p className="text-center mb-4">Or arrange a non-binding <a href="/erstgespraech?german=false">initial consultation &rarr;</a></p>
          <div className="row justify-content-center">
            <div className="col-md-8">
            <form onSubmit={sendMail}>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Your Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    E-Mail
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Your E-Mail Address"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="message" className="form-label">
                    Message
                  </label>
                  <textarea
                    className="form-control"
                    id="message"
                    rows="5"
                    placeholder="Your Message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="btn w-100"
                  style={{
                    backgroundColor: "var(--primary-color)",
                    color: "white",
                    border: "none",
                  }}
                  disabled={isSending}
                >
                  {isSending ? "Sending..." : <i class="bi bi-send-fill"></i>}
                </button>
              </form>
            </div>
          </div>
        </div>)
        : (
          <div className="container" id="contact">
          <h2 className="text-center mb-4">Wollen Sie mehr erfahren?</h2>
          <p className="text-center mb-4">Oder vereinbaren Sie ein unverbindliches <a href="/erstgespraech?german=true">Erstgespräch &rarr;</a></p>
          <div className="row justify-content-center">
            <div className="col-md-8">
            <form onSubmit={sendMail}>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Ihr Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    E-Mail
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Ihre E-Mail Adresse"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="message" className="form-label">
                    Nachricht
                  </label>
                  <textarea
                    className="form-control"
                    id="message"
                    rows="5"
                    placeholder="Ihre Nachricht"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="btn w-100"
                  style={{
                    backgroundColor: "var(--primary-color)",
                    color: "white",
                    border: "none",
                  }}
                  disabled={isSending}
                >
                  {isSending ? "Wird gesendet..." : <i class="bi bi-send-fill"></i>}
                </button>
              </form>
            </div>
          </div>
        </div>
        )}
      </section>

      <footer className="bg-dark text-white text-center py-3">
        <div className="container">
          <p className="mb-0">
            © {new Date().getFullYear()} Leu Consulting. All Rights Reserved.
          </p>
          <a 
          href="/imprint" 
          className="text-white-50"
          style={{
            marginRight: "1em",
          }}
          >Imprint</a>|  
          <a 
          href="https://www.linkedin.com/in/maxwell-leu-a33334225/"
          style={{
            marginLeft: "1em",
            marginRight: "1em"
          }}
          >
            <i class="bi bi-linkedin"></i>          
          </a>|  
          <a 
          href="https://github.com/CmdIglo"
          style={{
            marginLeft: "1em",
          }}
          >
            <i class="bi bi-github"></i>          
          </a>
        </div>
      </footer>
    </div>
  );
}

export default App;
