import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import '../App.css';

function OurVision({ german }) {
    return(
        <>
            <section className="bg-secondary text-white py-5" style={{
              padding: "10em",
              boxShadow: "0px -3px 50px -6px rgba(0,0,0,0.4)",
            }}>
              <div className="container">
                <div className="row align-items-center">
                  {!german ? (
                    <div className="col-md-6 order-md-2">
                      <h2 className="text-end" id="our-vision">My Vision</h2>
                      <p className="text-end">
                      As a freelance consultant and developer I envision a future where businesses thrive 
                      through digital transformation. My mission is to help 
                      companies automate and optimize their processes with innovative 
                      technologies. By creating custom software solutions, I want to empower 
                      clients to reach their full potential and achieve sustainable 
                      success in a rapidly changing digital landscape.
                      Together with you, I want to shape the future of business.
                      </p>
                    </div>
                  ) : (
                    <div className="col-md-6 order-md-2">
                      <h2 className="text-end" id="our-vision">Meine Vision</h2>
                      <p className="text-end">
                      Als freiberuflicher Berater und Entwickler stelle ich mir eine Zukunft vor, 
                      in der Unternehmen durch digitale Transformation erfolgreich sind. 
                      Meine Mission ist es, Unternehmen dabei zu helfen, ihre Prozesse 
                      mit innovativen Technologien zu automatisieren und zu optimieren. Durch 
                      die Entwicklung maßgeschneiderter Softwarelösungen möchte ich Kunden dabei 
                      unterstützen, ihr volles Potenzial auszuschöpfen und in einer sich 
                      schnell verändernden digitalen Landschaft nachhaltigen Erfolg zu erzielen. 
                      Gemeinsam mit Ihnen möchte ich die Zukunft des Geschäfts gestalten.
                      </p>
                    </div>
                  )}
                  <div className="col-md-6 order-md-1">
                    <img
                      src="/assets/vision_picture.jpg"
                      alt="Vision"
                      className="img-fluid rounded shadow-lg"
                    />
                  </div>
                </div>
              </div>
            </section>
        </>
    );
}

export default OurVision;