import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import '../App.css';

function WhatWeDo({ german }) {
    return(
        <>
            <section className="bg-secondary text-white py-5" style={{
        boxShadow: "0px -3px 50px -6px rgba(0,0,0,0.48)",
        zIndex: 1029,   
      }}>
        {!german ? (
          <div className="container">
          <h2 className="text-center mb-4" id="what-we-do">What I Do</h2>
          <p className="text-center mb-5 fs-5 fw-light">
            <em>
            Tailor-made software solutions, intelligent automation, and sustainable success – 
            I optimize your processes and drive innovation to ensure your company thrives in the digital world.           
            </em>
          </p>
          <div className="row text-center g-4">
            <div className="col-md-4">
              <div className="card bg-dark text-white border-0 shadow">
                <div className="card-body">
                  <div className="mb-3">
                    <i className="bi bi-code-slash fs-1 text-primary"></i>
                  </div>
                  <h4 className="card-title">Custom Software Development</h4>
                  <p className="card-text">
                    Develop bespoke software tailored to your business needs, ensuring seamless integration.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card bg-dark text-white border-0 shadow">
                <div className="card-body">
                  <div className="mb-3">
                    <i className="bi bi-gear-fill fs-1 text-primary"></i>
                  </div>
                  <h4 className="card-title">Process Automation</h4>
                  <p className="card-text">
                    Streamline operations with individual automation solutions that save time and resources.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card bg-dark text-white border-0 shadow">
                <div className="card-body">
                  <div className="mb-3">
                    <i className="bi bi-graph-up-arrow fs-1 text-primary"></i>
                  </div>
                  <h4 className="card-title">Digital Strategy</h4>
                  <p className="card-text">
                    Leverage my expertise and implement digitalization strategies that drive growth and innovation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>)
        : (
          <div className="container">
          <h2 className="text-center mb-4" id="what-we-do">Was biete ich?</h2>
          <p className="text-center mb-5 fs-5 fw-light">
            <em>
            Maßgeschneiderte Softwarelösungen, intelligente Automatisierung, nachhaltiger Erfolg – 
            Ich optimiere Ihre Prozesse und treibe Innovationen voran, damit Ihr Unternehmen in der digitalen Welt erfolgreich bleibt.           
            </em>
          </p>
          <div className="row text-center g-4">
            <div className="col-md-4">
              <div className="card bg-dark text-white border-0 shadow">
                <div className="card-body">
                  <div className="mb-3">
                    <i className="bi bi-code-slash fs-1 text-primary"></i>
                  </div>
                  <h4 className="card-title">Individuelle Softwareentwicklung</h4>
                  <p className="card-text">
                   Entwicklung maßgeschneiderter Software, die auf Ihre Geschäftsanforderungen zugeschnitten ist und nahtlose Integration gewährleistet.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card bg-dark text-white border-0 shadow">
                <div className="card-body">
                  <div className="mb-3">
                    <i className="bi bi-gear-fill fs-1 text-primary"></i>
                  </div>
                  <h4 className="card-title">Prozessautomatisierung</h4>
                  <p className="card-text">
                  Optimierung von Abläufen mit individuellen Automatisierungslösungen, die Zeit und Ressourcen sparen.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card bg-dark text-white border-0 shadow">
                <div className="card-body">
                  <div className="mb-3">
                    <i className="bi bi-graph-up-arrow fs-1 text-primary"></i>
                  </div>
                  <h4 className="card-title">Digitale Strategie</h4>
                  <p className="card-text">
                  Implementierung von Digitalisierungsstrategien, die Wachstum und Innovation vorantreiben.                  
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        )}
      </section>
        </>
    );
}

export default WhatWeDo;