import React, { useState } from "react";
import { useSearchParams, Link } from "react-router-dom"; // Link importieren
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Erstgespraech() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [message, setMessage] = useState("");

  const [searchParams] = useSearchParams();
  const german = searchParams.get("german") === "true";

  const saveAsICSFile = () => {
    if (!selectedDate) {
      alert(
        german
          ? "Bitte wählen Sie zuerst ein Datum und eine Uhrzeit aus."
          : "Please select a date and time first."
      );
      return;
    }

    const eventDetails = {
      title: german
        ? "Erstgespräch Termin"
        : "Initial Consultation Appointment",
      description:
        message || (german ? "Keine zusätzliche Nachricht." : "No additional message provided."),
      startDate: selectedDate,
      endDate: new Date(selectedDate.getTime() + 60 * 60 * 1000), // 1 hour duration
    };

    const icsContent = `
BEGIN:VCALENDAR
VERSION:2.0
CALSCALE:GREGORIAN
BEGIN:VEVENT
SUMMARY:${eventDetails.title}
DESCRIPTION:${eventDetails.description}
DTSTART:${formatDateToICS(eventDetails.startDate)}
DTEND:${formatDateToICS(eventDetails.endDate)}
END:VEVENT
END:VCALENDAR`;

    const blob = new Blob([icsContent], { type: "text/calendar" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "appointment.ics";
    link.click();
  };

  const formatDateToICS = (date) => {
    return date.toISOString().replace(/[-:]/g, "").split(".")[0] + "Z";
  };

  const createGoogleCalendarLink = () => {
    if (!selectedDate) {
      alert(
        german
          ? "Bitte wählen Sie zuerst ein Datum und eine Uhrzeit aus."
          : "Please select a date and time first."
      );
      return;
    }

    const startDate = encodeURIComponent(formatDateToGoogle(selectedDate));
    const endDate = encodeURIComponent(
      formatDateToGoogle(new Date(selectedDate.getTime() + 60 * 60 * 1000)) // 1 hour duration
    );
    const text = encodeURIComponent(
      german ? "Erstgespräch Termin" : "Initial Consultation Appointment"
    );
    const details = encodeURIComponent(
      message || (german ? "Keine zusätzliche Nachricht." : "No additional message provided.")
    );
    const guests = encodeURIComponent("leonard.maxwell.leu@gmail.com"); // Your email as guest

    const googleCalendarURL = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${text}&dates=${startDate}/${endDate}&details=${details}&add=${guests}`;
    window.open(googleCalendarURL, "_blank");
  };

  const formatDateToGoogle = (date) => {
    return date.toISOString().replace(/[-:]/g, "").split(".")[0] + "Z";
  };

  return (
    <div>
      {/* Zurück-Button */}
      <Link to="/">
        <button
          style={{
            position: "fixed",
            top: "10px",
            left: "10px",
            padding: "10px 20px",
            fontSize: "16px",
            color: "#fff",
            backgroundColor: "#007BFF",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            zIndex: "1000",
          }}
        >
          {german ? "Zurück" : "Back"}
        </button>
      </Link>

      {/* Zentrierter Content */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          textAlign: "center",
        }}
      >
        <h3>{german ? "Planen Sie Ihren Termin" : "Schedule Your Appointment"}</h3>
        <div>
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            showTimeSelect
            inline
            timeFormat="HH:mm"
            timeIntervals={30}
            dateFormat={german ? "d. MMMM yyyy, HH:mm" : "MMMM d, yyyy h:mm aa"}
            placeholderText={
              german
                ? "Wählen Sie ein Datum und eine Uhrzeit"
                : "Select a date and time"
            }
          />
        </div>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder={
            german
              ? "Fügen Sie eine optionale Nachricht hinzu..."
              : "Add an optional message..."
          }
          style={{
            marginTop: "20px",
            padding: "10px",
            width: "300px",
            height: "100px",
            resize: "none",
            border: "1px solid #ccc",
            borderRadius: "4px",
          }}
        />
        <p>
          {german
            ? 'Um die Terminplanung abzuschließen, klicken Sie bitte auf "Zum Google Kalender hinzufügen" und speichern Sie den Termin dort.'
            : 'To complete your appointment scheduling please press "Add to Google Calendar" and save the meeting there.'}
        </p>
        <button
          onClick={saveAsICSFile}
          style={{
            marginTop: "20px",
            padding: "10px 20px",
            fontSize: "16px",
            color: "#fff",
            backgroundColor: "#007BFF",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          {german ? "Termin als Datei speichern" : "Save Appointment as File"}
        </button>
        <button
          onClick={createGoogleCalendarLink}
          style={{
            marginTop: "10px",
            padding: "10px 20px",
            fontSize: "16px",
            color: "#fff",
            backgroundColor: "#34A853",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          {german ? "Zum Google Kalender hinzufügen" : "Add to Google Calendar"}
        </button>
      </div>
    </div>
  );
}

export default Erstgespraech;
