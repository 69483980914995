import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import '../App.css';

function MySkills({ german }) {
    return (
        <>
            <section className="bg-light py-5" style={{ 
                height: "auto", 
                boxShadow: "0px -3px 50px -6px rgba(0,0,0,0.4)",
            }}>
                <div className="container" id="thisiswhatioffer">
                    <h2 className="mb-5 text-center">
                        {german ? "Meine Dienstleistungen" : "My services"}
                    </h2>
                    <ul className="list-group">
                        <li 
                            className="list-group-item d-flex align-items-center mb-4 shadow-sm"
                            style={{ padding: "20px", borderRadius: "10px", background: "#f9f9f9" }}
                        >
                            <i className="bi bi-globe me-3 text-primary" style={{ fontSize: "2rem" }}></i>
                            <span style={{ fontWeight: "500", fontSize: "1.2rem" }}>
                                {german 
                                    ? "Webseiten Entwicklung und Verwaltung" 
                                    : "Website development and management"}
                            </span>
                        </li>
                        <li 
                            className="list-group-item d-flex align-items-center mb-4 shadow-sm"
                            style={{ padding: "20px", borderRadius: "10px", background: "#f9f9f9" }}
                        >
                            <i className="bi bi-gear-fill me-3 text-primary" style={{ fontSize: "2rem" }}></i>
                            <span style={{ fontWeight: "500", fontSize: "1.2rem" }}>
                                {german 
                                    ? "Entwicklung von Automatisierungssoftware und -lösungen" 
                                    : "Development of automation software and solutions"}
                            </span>
                        </li>
                        <li 
                            className="list-group-item d-flex align-items-center mb-4 shadow-sm"
                            style={{ padding: "20px", borderRadius: "10px", background: "#f9f9f9" }}
                        >
                            <i className="bi bi-code-slash me-3 text-primary" style={{ fontSize: "2rem" }}></i>
                            <span style={{ fontWeight: "500", fontSize: "1.2rem" }}>
                                {german 
                                    ? "Entwicklung von individueller Software" 
                                    : "Development of custom software"}
                            </span>
                        </li>
                        <li 
                            className="list-group-item d-flex align-items-center shadow-sm"
                            style={{ padding: "20px", borderRadius: "10px", background: "#f9f9f9" }}
                        >
                            <i className="bi bi-lightbulb me-3 text-primary" style={{ fontSize: "2rem" }}></i>
                            <span style={{ fontWeight: "500", fontSize: "1.2rem" }}>
                                {german 
                                    ? "Digitalisierungs- und IT-Beratung" 
                                    : "Digitalization and IT consulting"}
                            </span>
                        </li>
                    </ul>
                </div>
            </section>
        </>
    );
}

export default MySkills;
