import React from "react";

function Imprint() {
    return(
        <div className='impressum'>
            <head>
            <title>Leu Consulting</title>
            <link rel="icon" href="assets/favicon-white.png"></link>
            </head>
            <h1>Impressum</h1>
            <p>Angaben gemäß § 5 DDG</p>
            <p>
                Maxwell Leu <br />
                Oldenfelder Straße 58 <br />
                22143 Hamburg <br />
            </p>
            <p>
                <strong>Vertreten durch: </strong><br />
                Maxwell Leu <br />
            </p>
            <p>
                <strong>Kontakt:</strong><br />
                Telefon: +49-15774911341 <br />
                E-Mail: <a href='mailto:email@maxwell-leu.de'>email@maxwell-leu.de</a>
            </p>
            <br />
            Impressum vom <a href="https://www.impressum-generator.de">Impressum Generator</a> der <a href="https://www.kanzlei-hasselbach.de/standorte/bonn/" rel="nofollow">Kanzlei Hasselbach, Bonn</a>
        </div>
    );
}

export default Imprint;
