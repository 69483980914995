import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import '../App.css';

function OurServices({ german }) {
    return(
        <>
            <section className="bg-light py-5" style={{ 
                height: "auto", 
                boxShadow: "0px -3px 50px -6px rgba(0,0,0,0.4)",
              }}>
              <div className="container">
                {!german ? (
                  <h2 className="text-center mb-4">My Services</h2>
                ) : (
                  <h2 className="text-center mb-4">Meine Dienstleistungen</h2>
                )}
                <div className="row d-flex justify-content-center">
                  <div className="col-12 text-center d-flex justify-content-center align-items-center rounded shadow-lg bg-light">
                  {!german ? (<img
                    id="our-services-img"
                    src="./assets/napkin-selection.png"
                    alt="My Services"
                  />) : (
                  <img
                    id="our-services-img"
                    src="./assets/napkin-selection-ger.png"
                    alt="My Services"
                  />
                  )}
                  </div>
                </div>
              </div>
            </section>

        </>
    );
}

export default OurServices;