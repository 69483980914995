import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import '../App.css';

function TrustedBy({ german }) {
    return(
        <>
            <section className="bg-secondary text-white py-5" style={{
              padding: "10em",
              boxShadow: "0px -3px 50px -6px rgba(0,0,0,0.4)",
            }}>
              <div className="container">
                {!german ? (<h2 className="text-center mb-4">Trusted By</h2>)
                : (<h2 className="text-center mb-4">Diese Firmen vertrauen mir</h2>)}
                <div 
                className="row d-flex justify-content-center">
                  <div
                    className="col-md-4 text-center rounded shadow-lg bg-light experience-logo-container"
                    style={{
                      padding: "2em",
                      margin: "1em",
                    }}
                  >
                   <a href="https://www.airbus.com">
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/2/24/Airbus_logo_2017.png"
                      className="experience-logo img-fluid"
                      alt="Airbus Logo"
                    />
                    </a>
                  </div>
                  <div
                    className="col-md-4 text-center rounded shadow-lg bg-light experience-logo-container"
                    style={{
                      padding: "2em",
                      margin: "1em",
                    }}
                  >
                  <a href="https://www.satair.com">
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/a/a8/Satair_Logo.jpg"
                      className="experience-logo img-fluid"
                      alt="Satair Logo"
                    />
                    </a>
                  </div>
                  <div
                    className="col-md-4 text-center rounded shadow-lg bg-light experience-logo-container"
                    style={{
                      padding: "2em",
                      margin: "1em",
                    }}
                  >
                  <a href="https://www.verlagdrkovac.de/">
                    <img
                      src="https://www.verlagdrkovac.de/css-2020/logo-verlag-dr-kovac-2.svg"
                      className="experience-logo img-fluid"
                      alt="Verlag Dr Kovac Logo"
                    />
                    </a>
                  </div>
                </div>
              </div>
            </section>
        </>
    );
}

export default TrustedBy;